type Props = {
  src: string,
  alt: string,
  className?: string,
  width?: string | number,
  height?: string | number,
}

const Image = ({ src, alt, width, height, className }: Props) => <img srcSet={src} alt={alt} className={className} width={width} height={height}/>
 
export default Image;