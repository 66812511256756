import { Container } from "react-bootstrap";
import { AppTheme } from "../../modules/enums";
import { useTheme } from "../hooks/useTheme";
import Image from "./Image";
import Slider from "react-slick";

const Brands = ({ theme }: { theme?: AppTheme }) => {
  const appTheme = useTheme();
  const darkMode = appTheme.theme === AppTheme.DARK || theme === AppTheme.DARK;

  const ReactSlickSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    cssease: "linear",
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }

  return (
    <Container fluid className="mt-md-130 mt-5">
      <p className={`font-size-23 ${darkMode ? "text-white" : "text-dark"} text-center`}>Featured in the News</p>
      <div className="slider-container mt-4 mt-lg-5">
        <Slider {...ReactSlickSettings}>
          <Image
            alt="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&"
            className="mx-auto" />
          <Image
            alt="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&"
            className="mx-auto" />
          <Image
            alt="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e45e7a1e1d8f6f9438822ed5dd569dbbe062e831924e865319217dc45512a50e?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e45e7a1e1d8f6f9438822ed5dd569dbbe062e831924e865319217dc45512a50e?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e45e7a1e1d8f6f9438822ed5dd569dbbe062e831924e865319217dc45512a50e?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e45e7a1e1d8f6f9438822ed5dd569dbbe062e831924e865319217dc45512a50e?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e45e7a1e1d8f6f9438822ed5dd569dbbe062e831924e865319217dc45512a50e?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e45e7a1e1d8f6f9438822ed5dd569dbbe062e831924e865319217dc45512a50e?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e45e7a1e1d8f6f9438822ed5dd569dbbe062e831924e865319217dc45512a50e?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e45e7a1e1d8f6f9438822ed5dd569dbbe062e831924e865319217dc45512a50e?apiKey=7ed81b7744384cc0909bd2ca97455c68&"
            className="mx-auto" />
          <Image
            alt="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/647d3ac9132c61de9382d291066cca856c248c1ee957cde1d0936264f3d03111?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/647d3ac9132c61de9382d291066cca856c248c1ee957cde1d0936264f3d03111?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/647d3ac9132c61de9382d291066cca856c248c1ee957cde1d0936264f3d03111?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/647d3ac9132c61de9382d291066cca856c248c1ee957cde1d0936264f3d03111?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/647d3ac9132c61de9382d291066cca856c248c1ee957cde1d0936264f3d03111?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/647d3ac9132c61de9382d291066cca856c248c1ee957cde1d0936264f3d03111?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/647d3ac9132c61de9382d291066cca856c248c1ee957cde1d0936264f3d03111?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/647d3ac9132c61de9382d291066cca856c248c1ee957cde1d0936264f3d03111?apiKey=7ed81b7744384cc0909bd2ca97455c68&"
            className="mx-auto" />
          <Image
            alt="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/62b97443c683a3d54d2173051bb606f617baeead382cd217cf6e5f442e6d3809?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/62b97443c683a3d54d2173051bb606f617baeead382cd217cf6e5f442e6d3809?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/62b97443c683a3d54d2173051bb606f617baeead382cd217cf6e5f442e6d3809?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/62b97443c683a3d54d2173051bb606f617baeead382cd217cf6e5f442e6d3809?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/62b97443c683a3d54d2173051bb606f617baeead382cd217cf6e5f442e6d3809?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/62b97443c683a3d54d2173051bb606f617baeead382cd217cf6e5f442e6d3809?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/62b97443c683a3d54d2173051bb606f617baeead382cd217cf6e5f442e6d3809?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/62b97443c683a3d54d2173051bb606f617baeead382cd217cf6e5f442e6d3809?apiKey=7ed81b7744384cc0909bd2ca97455c68&"
            className="mx-auto" />
          <Image
            alt="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/04370de0aeb5679d4a6528d84ff3a8f7a206b3bd7c32050b41ea28d630e0bf25?apiKey=7ed81b7744384cc0909bd2ca97455c68&"
            className="mx-auto" />
          <Image
            alt="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aeeba496362d1aeb968faffb6f4489247e99740e9b7def7851710f914dee1b3?apiKey=7ed81b7744384cc0909bd2ca97455c68&"
            className="mx-auto" />
        </Slider>
      </div>
    </Container>
  )
}

export default Brands;