import { PolicyLinkPlacement, LinkType } from "../../modules/enums";

export const Terms: IPolicies[] = [
  {
    title: `Accounts and membership`,
    description: `You must be at least 21 years of age to use the Website and Services. By using the Website and Services and by agreeing to this Agreement you warrant and represent that you are at least 21 years of age. 
                  If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. 
                  We may monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. 
                  We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. 
                  If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.`,
  },
  {
    title: `User content`,
    description: `We do not own any data, information or content that you submit on the Website or mobile app in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. 
                  You grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. 
                  You also grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose.`,
  },
  {
    title: `Backups`,
    description: ` We perform regular backups of the Website and its Content and will do our best to ensure completeness and accuracy of these backups. 
                  In the event of the hardware failure or data loss we will restore backups automatically to minimize the impact and downtime.`,
  },
  {
    title: `Links to other resources`,
    description: ` Although the Website and Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, 
                  sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein.`,
  },
  {
    title: `Limitation of Liability`,
    extras: [
      {
        description: `This Platform is provided on an "as is" and "as available" basis. We do not represent, warrant or guarantee that access to the platform will
         be uninterrupted or error free at all times. Where faults occur in the application, we shall endeavor to correct them as soon as possible.
          However, functioning of the platform may be restricted due to occasional technical issues which may result in a service interruption.`
      },
      {
        description: `While we make every effort to provide vehicles that are fit for the particular purpose, we shall not be liable where the vehicle size or quality does not match User's expectations.
`
      },
      {
        description: `User shall be fully liable for breach of these Terms, Agreement or any other applicable laws or regulations.`
      },
      {
        description: `User shall be liable for any false information provided in the course of using our services.`
      },
      {
        description: `When a request is canceled by User after a certain time period, User is required to pay a cancellation fee.`
      },
      {
        description: `We shall not be liable where there is a delay in pickup or delivery of the goods in the following instances`
      },
      {
        description: `User provides wrong information or does other actions that cause delay`
      },
      {
        description: `Other circumstances and peculiarities beyond our control including but not limited to insecurity, ongoing constructions on delivery routes and government order`
      }
    ]
  },
  {
    title: `Intellectual Property Rights`,
    description: `All content on this Website is protected by trademarks, service marks, and/or other intellectual property rights and are owned and controlled by truQ and its affiliates. You acknowledge that no intellectual property rights are transferred, no licenses are granted and you do not acquire any ownership rights by using this Website. You must not reproduce, distribute, transmit, create derivative works of, or store any material on this Website except if incidental to normal web browsing.`,
    footer: `If you violate any of these Terms, your permission to use our Website immediately terminates without notice, in addition to other remedies truQ and its affiliates may have.`
  },
  {
    title: `Prohibited uses`,
    description: `In addition to other terms as set forth in the Agreement, you are prohibited from using the Website and Services or Content:`,
    extras: [
      {
        description: `for any unlawful purpose;`
      },
      {
        description: `to solicit others to perform or participate in any unlawful acts;`
      },
      {
        description: `to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; `
      },
      {
        description: `to infringe upon or violate our intellectual property rights or the intellectual property rights of others;`
      },
      {
        description: `to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; `
      },
      {
        description: `to submit false or misleading information;`
      },
      {
        description: `to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Website and Services, third party products and services, or the Internet;`
      },
      {
        description: `to spam, phish, pharm, pretext, spider, crawl, or scrape;`
      },
      {
        description: `for any obscene or immoral purpose; or`
      },
      {
        description: `to interfere with or circumvent the security features of the Website and Services, third party products and services, or the Internet.`
      }
    ],
    footer: `We reserve the right to terminate your use of the Website and Services for violating any of the prohibited uses.`
  },
  {
    title: `Severability`,
    description: `All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.`,
  },
  {
    title: `Governing Law and Dispute resolution`,
    description: `The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of Nigeria without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of Nigeria. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in Nigeria, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement.`,
  },
  {
    title: `Changes and amendments`,
    description: `We reserve the right to modify this Agreement or its terms relating to the Website and Services at any time, effective upon posting of an updated version of this Agreement on the Website. When we do, we will revise the updated date at the bottom of this page. Continued use of the Website and Services after any such changes shall constitute your consent to such changes`,
  },
  {
    title: `Acceptance of these terms`,
    description: `You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Website and Services.`,
  },
  {
    title: `Contact us`,
    description: `Send an email to `,
    link: {
      href: `contact@mytruq.com`,
      type: LinkType.EMAIL,
      placement: PolicyLinkPlacement.DESCRIPTION
    },
    footer: `This document was last updated on November 23rd, 2022.`,
    extras: [
      {
        description: `you would like to contact us to understand more about this Agreement `
      },
      {
        description: `you desire to submit a complaint regarding our services`
      },
      {
        description: `you wish to contact us concerning any matter relating to this Agreement`
      }
    ]
  }
]