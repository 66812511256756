//ALPHABETICAL ORDER
export enum AppTheme {
  DARK = "DARK",
  LIGHT = "LIGHT"
};

export enum ColumnOrder {
  FIRST = "FIRST",
  SECOND = "SECOND",
  THIRD = "THIRD",
};

export enum ErrorNavType {
  G0_BACK = "G0_BACK",
  HOME = "HOME"
}

export enum LEGALS {
  PRIVACY_POLICY = "PRIVACY_POLICY",
  TERMS_CONDITION = "TERMS_CONDITION",
  TERMS_DRIVER_PATNER = "TERMS_DRIVER_PATNER",
};

export enum LinkType {
  EMAIL = "EMAIL",
  TARGET = "TARGET",
  IN_APP = "IN_APP",
};

export enum MenuType {
  HOME = "home",
  LEGAL = "legal",
  COMPANY = "company",
  PRODUCTS = "products",
  RESOURCES = "resources",
  UNAUTHORISED = "unauthorised",
  CONSTRUCTION = "under-construction",
};

export enum NavItemType {
  PRODUCTS = "PRODUCTS",
  COMPANY = "COMPANY",
  RESOURCES = "RESOURCES"
}

export enum PolicyLinkPlacement {
  MORE = "MORE",
  TITLE = "TITLE",
  FOOTER = "FOOTER",
  SUBTITLE = "SUBTITLE",
  DESCRIPTION = "DESCRIPTION",
};

export enum SubMenuType {
  SIJU = "siju-by-truq",
  DRIVEHUB = "drivehub",
  TRUQ_PAY = "truq-pay",
  TRUQ = "move-by-truq",
  PRIVACY_POLICY = "privacy-policy",
  TERMS_CONDITION = "terms-conditions",
  TERMS_DRIVER_PATNER = "terms-driver-partner",
  CAREER = "career",
  ABOUT_US = "about-us",
  CONTACT_US = "contact-us",
  FREQUENTLY_ASKED_QUESTIONS = "frequently-asked-questions"
};