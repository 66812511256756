import AccordionComponent from "../../components/fragment/Accordion";
import { Questions } from "../../utils/mapper/faqs";
import PageLayout from "../../components/layouts/PageLayout"
import { AppTheme } from "../../modules/enums";
import { useTheme } from "../../components/hooks/useTheme";
import { useEffect } from "react";


const FAQs = () => {
  const appTheme = useTheme();

  useEffect(() => {
    if (appTheme.theme === AppTheme.DARK) appTheme.toggleTheme(AppTheme.LIGHT);
  }, [appTheme.theme]);

  
  return (
    <PageLayout>
      <div className="d-flex flex-column align-items-center mt-5 mt-md-70">
        <h2 className="text-dark text-md-center mt-5 mb-md-5 px-3 px-md-5">Frequently Asked Questions</h2>
        <AccordionComponent accordionData={Questions} className="accordion-title" />
      </div>
    </PageLayout>
  )
}

export default FAQs