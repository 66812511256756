export const Questions: IAccordionData[] = [
    {
      id: "01",  
      title: `How do I sign up as a driver?`,
      description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus nostrum vitae deserunt in reprehenderit, quaerat maiores ullam quisquam! Nihil aliquam, deleniti dicta odit ex vel you can be a driver!`,
    },
    {
        id: "02",  
        title: `How do I book a trip ?`,
        description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus nostrum vitae deserunt in reprehenderit, quaerat maiores ullam quisquam! Nihil aliquam, deleniti dicta odit ex vel you can book a trip!`,
    },
    {
        id: "03",  
        title: `How do i sign up as a merchant ?`,
        description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus nostrum vitae deserunt in reprehenderit, quaerat maiores ullam quisquam! Nihil aliquam, deleniti dicta odit ex vel you can be a merchant!`,
    },
    {
        id: "04",  
        title: `What is Siju ?`,
        description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus nostrum vitae deserunt in reprehenderit, quaerat maiores ullam quisquam! Nihil aliquam, deleniti dicta odit ex vel ipsam is what Siju is!`,
    },
    {
        id: "05",  
        title: `How do i download the Driver App ?`,
        description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus nostrum vitae deserunt in reprehenderit, quaerat maiores ullam quisquam! Nihil aliquam, deleniti dicta odit ex you can download the driver app!`,
    }
  ]