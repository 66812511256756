import Slider from "react-slick";
import Image from "./Image";
import { Testimonies } from "../../utils/mapper/Mapper";

const ReactSlickSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 5,
  autoplay: true,
  autoplaySpeed: 2000,
  cssease: "linear",
  pauseOnHover: true,
  className: "center",
  centerMode: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
}
const Testimony = () => (
  <section className="d-flex flex-column">
    <div className="bg-light d-flex flex-column py-50">
      <div className="d-flex flex-column align-self-center mt-5">
        <h3 className="text-darker text-center">Don't take our word for it</h3>
        <p className="text-medium text-center mt-3">
          Check out what our Partners are saying about us
        </p>
      </div>
      <div className="slider-container my-3 my-lg-5">
        <Slider {...ReactSlickSettings}>
          {Testimonies.map((data, index) =>
            <div key={index} className="px-2 d-flex justify-content-center">
              <div className="card h-100 w-300">
                <div className="card-body">
                  <span className="testimony-text">{data.content}</span>
                  <div className="d-flex gap-3 mt-3">
                    {data.image
                      ? <Image
                        alt="lazy"
                        src={data.image}
                        className="rounded-circle"
                        width="40" height="40"
                      />
                      : <div style={{ width: "40px", height: "40px"}} 
                        className={`d-flex justify-content-center align-items-center rounded-circle ${data.imageBg}`}>
                            {data.author[0].toUpperCase()}
                        </div>
                    }
                    
                    <div className="my-1">
                      <p className="font-size-14 fw-bold">{data.author}</p>
                      <small className="font-size-12">{data.medium}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Slider>
      </div>
    </div>
  </section>
);

export default Testimony;