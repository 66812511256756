import { Button } from "react-bootstrap";
import Image from "../fragment/Image";
import PlaystoreSVG from "../../assets/images/svgs/playstore.svg";

const PlayStore = () => (
  <Button variant="dark" className="d-flex align-items-center gap-2 gap-md-3" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.mytruq.driver")}>
    <Image
      className="m-auto"
      src={PlaystoreSVG}
      alt="PLAY_STORE"
      width={16}
      height={16}
    />
    <span className="d-flex flex-column">
      <small className="text-smaller font-weight-400 font-size-9 text-start">Download Driver app</small>
      <h6 className="font-weight-700 font-size-14 text-start mt-0">Google Play</h6>
    </span>
  </Button>
);


export default PlayStore;