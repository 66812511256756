import axios, {InternalAxiosRequestConfig, AxiosHeaders} from "axios";
import { generalAppInfo } from "./constants";

export const LOCALHOST = "localhost";
export const BETA_BASEURL = "beta.mytruq.com";
export const PROD_BASEURL = "mytruq.com";

export const getBaseUrlEnv = () => {
    if (typeof window !== "undefined") {
      if (
        window.location.hostname === LOCALHOST ||
        window.location.hostname === BETA_BASEURL
      ) return "SANDBOX";
      else return "PRODUCTION";
    }
  };
  
  export const getBaseUrl = () => {
    let web = generalAppInfo.API_BASE_URL,  analytics = generalAppInfo.BACKOFFICE_BASE_URL, organisation = generalAppInfo.ORGANISATION, checkout = generalAppInfo.CHECKOUT_BASE_URL
    if (
      window.location.hostname === LOCALHOST ||
      window.location.hostname === BETA_BASEURL
    ) {
        web = generalAppInfo.API_STAGING_BASE_URL;
        analytics = generalAppInfo.BACKOFFICE_STAGING_BASE_URL;
        organisation = generalAppInfo.STAGING_ORGANISATION;
        checkout = generalAppInfo.CHECKOUT_STAGING_BASE_URL;
    }
   else if (
      window.location.hostname === PROD_BASEURL 
    ) {
        web = generalAppInfo.API_BASE_URL;
        analytics = generalAppInfo.BACKOFFICE_BASE_URL;
        organisation = generalAppInfo.ORGANISATION;
        checkout = generalAppInfo.CHECKOUT_BASE_URL;
    }
  
    return { web, analytics, organisation, checkout };
  };
  
  const headers = {"Content-Type": "application/json", organisation: `${getBaseUrl()['organisation']}`}
  const dodgeInstance = axios.create({ baseURL: getBaseUrl()['web'], headers });
  const backOfficeInstance = axios.create({ baseURL: getBaseUrl()['analytics'] });
  
  dodgeInstance.interceptors.request.use((config: InternalAxiosRequestConfig ) => {
    const token = getBaseUrlEnv() === 'PRODUCTION' ? generalAppInfo.SIJU_PK : generalAppInfo.SIJU_STAGING_PK;    
    if (!config.headers) {
        config.headers = new AxiosHeaders();
      } 
    config.headers.set({ Authorization: `Bearer ${token}`, ...config.headers })
    return config;
  }, error => Promise.reject(error));
  
  dodgeInstance.interceptors.response.use(response => response, error => {
    return error.response;
  });
  
  backOfficeInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    if (!config.headers) {
        config.headers = new AxiosHeaders();
      }      
    config.headers.set({ "Content-Type": "application/json", ...config.headers })
    return config;
  }, error => Promise.reject(error));
  
  backOfficeInstance.interceptors.response.use(response => response, error => {
    return error.response;
  });
  
  export { dodgeInstance, backOfficeInstance };



