import Slider from "react-slick";
import { Stats } from "../../utils/mapper/Mapper";

const ReactSlickSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssease: "linear",
  pauseOnHover: false,
  className: "center",
  centerMode: true,
  arrows: false, 
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
      }
    }
  ]
}

const CompanyStat = () => (
  <div className="stat">
    <p className="text-muted text-center mb-5 px-3">
      Pioneering smarter and more fulfilling logistics for Africa, One driver at a time.
    </p>
    <Slider {...ReactSlickSettings}>
      {Stats.map((data, index) =>
        <div key={index} className="stat-item">
          <h4 className="text-primary text-center">{data.title}</h4>
          <span className="text-muted text-center font-size-14">{data.description}</span>
        </div>
      )}
    </Slider>
  </div>
);

export default CompanyStat;