import { Button } from "react-bootstrap";
import Image from "../fragment/Image";
import AppstoreSVG from "../../assets/images/svgs/appstore.svg";

const AppStore = () => (
  <Button variant="dark" className="d-flex align-items-center gap-2 gap-md-3 px-2 px-lg-3" onClick={() => window.open("https://apps.apple.com/ng/app/truq-driver/id1635132424")}>
    <Image
      className="m-auto"
      src={AppstoreSVG}
      alt="APP_STORE"
      width={20}
      height={20}
    />
    <span className="d-flex flex-column">
      <small className="text-smaller font-weight-400 font-size-9 text-start">Download Driver app</small>
      <h6 className="font-weight-700 font-size-14 text-start mt-0">App Store</h6>
    </span>
  </Button>
)

export default AppStore;