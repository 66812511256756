import { Accordion } from 'react-bootstrap';

type Props = {
  className?: string
  accordionData: IAccordionData[],
}

const AccordionComponent = ({ accordionData, className }: Props) => {
  return (
    <div>
      <Accordion flush defaultActiveKey="0">
        {accordionData.map((data, index) =>
          <Accordion.Item key={data.id} eventKey={`${index}`}>
            <Accordion.Header>
              {(data && data.id) && <p className='accordion-id'>{data.id}</p>}
              <p className={className}>{data.title}</p>
            </Accordion.Header>
            <Accordion.Body>
              {data.description}
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </div>
  )
}

export default AccordionComponent