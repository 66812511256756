import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MenuType, SubMenuType } from "./modules/enums";
import { Toaster } from "react-hot-toast";

import Home from "./pages/home/Home";
import Siju from "./pages/products/Siju";
import FAQs from "./pages/resources/FAQs";
import Career from "./pages/company/Career";
import AboutUs from "./pages/company/AboutUs";
import TruqPay from "./pages/products/TruqPay";
import Drivehub from "./pages/products/Drivehub";
import ContactUs from "./pages/company/ContactUs";
import MoveByTruq from "./pages/products/MoveByTruq";
import ServiceTerms from "./pages/legals/ServiceTerms";
import PartnerTerms from "./pages/legals/PartnerTerms";
import PrivacyPolicy from "./pages/legals/PrivacyPolicy";
import UseScrollToTop from "./components/hooks/useScrollToTop";
import Error403 from "./pages/misc/Error403";
import Error404 from "./pages/misc/Error404";
import UnderConstruction from "./pages/misc/UnderContruction";



const App = () => {

  return (
    <Router>
      <UseScrollToTop/>
      <Toaster containerStyle={{zIndex: '99999999'}} position="top-right" reverseOrder={false}/>
      <Routes>
        {['/', MenuType.HOME].map(path => <Route path={path} key={path} element={<Home />} />)}
        <Route path={MenuType.LEGAL}>
          <Route path={SubMenuType.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={SubMenuType.TERMS_DRIVER_PATNER} element={<PartnerTerms />} />
          <Route index path={SubMenuType.TERMS_CONDITION} element={<ServiceTerms />} />
        </Route>
        <Route path={MenuType.PRODUCTS}>
          <Route path={SubMenuType.SIJU} element={<Siju />} />
          {/* <Route path={SubMenuType.TRUQ_PAY} element={<TruqPay />} /> */}
          <Route path={SubMenuType.DRIVEHUB} element={<Drivehub />} />
          <Route index path={SubMenuType.TRUQ} element={<MoveByTruq />} />
        </Route>
        <Route path={MenuType.RESOURCES}>
          <Route path={SubMenuType.FREQUENTLY_ASKED_QUESTIONS} element={<FAQs />} />
        </Route>
        <Route path={MenuType.COMPANY}>
          <Route path={SubMenuType.ABOUT_US} element={<AboutUs />} />
          <Route path={SubMenuType.CAREER} element={<Career />} />
          <Route path={SubMenuType.CONTACT_US} element={<ContactUs />} />
        </Route>
        <Route path={MenuType.CONSTRUCTION} element={<UnderConstruction />} />
        <Route path={MenuType.UNAUTHORISED} element={<Error403 />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
}
export default App;
