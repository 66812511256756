import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";
import { Button, Col, Container, Row } from "react-bootstrap";

import Image from "../../components/fragment/Image";
import { Loading } from "../../utils/services/constants";
import CheckSVG from "../../assets/images/svgs/check.svg";
import APIService from "../../utils/services/api.service";
import { useTheme } from "../../components/hooks/useTheme";
import { AppTheme, ColumnOrder } from "../../modules/enums";
import Testimony from "../../components/fragment/Testimony";
import PageLayout from "../../components/layouts/PageLayout";
import { getBaseUrl } from "../../utils/services/axios.service";
import EmployeeInfo from "../../components/fragment/EmployeeInfo";
import AppStoreBanner from "../../components/fragment/AppStoreBanner";
import CollapsibleText from "../../components/fragment/CollapsibleText";
import { MovingExperience, UpgradeOptions } from "../../utils/mapper/Mapper";


type QuoteLoadState = {
  state: boolean,
  index: null | string,
}


const MoveByTruq = () => {
  const appTheme = useTheme();
  const [loading, setLoading] = useState<QuoteLoadState>({ state: false, index: null });

  useEffect(() => {
    if (appTheme.theme === AppTheme.DARK) appTheme.toggleTheme(AppTheme.LIGHT);
  }, [appTheme.theme]);

  const initiateQuote = async (type: string) => {
    setLoading({ state: true, index: type })
    const { data: responseData } = await APIService.initiateTrip();
    if (!responseData || !responseData.status || responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      if (!responseData.data || !responseData.data.quote || !responseData.data.quote.reference) toast.error("unable to initiate quote at the moment, please try again later")
      else {
        const checkoutUrl = `${getBaseUrl()['checkout']}/${responseData.data.quote.reference}`;
        toast.success(responseData.message);
        const popupWindow = window.open(checkoutUrl, "_blank");
        if (popupWindow && (!popupWindow.closed || typeof popupWindow.closed === 'undefined')) popupWindow.focus();
      }
    }
    setLoading({ state: false, index: type })
  }

  return (
    <PageLayout>
      <section className="truqPay-hero">
        <Container className="d-flex flex-column">
          <div className="text-center mt-2">
            <h1 className="w-720 w-100 mx-auto"> Moving <span className="text-orange"> Homes </span> Should Never Be Stressful</h1>
            <p className="hero-description">
              Get connected to the closest drivers running on truQ, access everything else you need and see for yourself how seamless it is moving with truQ.
            </p>
          </div>

          <div className="d-flex gap-4 my-3 my-md-5 justify-content-center">
            <Button className="px-5" variant="primary" onClick={() => initiateQuote("book")}>
              Book Now
              {(loading.state && loading.index === 'book') && <Spinner animation="border" size="sm" className="ms-2" />}
            </Button>
          </div>

          <div className="w-100 w-500 align-self-center">
            <Image
              alt="HERO"
              src="https://storage.googleapis.com/public_storage_truq/website/product_siju.png"
              className="mt-5 mt-md-0"
            />
          </div>
        </Container>
      </section>

      <Container>
        <section className="mt-5 mt-md-70 pt-0 pt-md-5">
          <div className="text-center align-self-center">
            <h2 className="text-darker mx-auto">We’ve Got All You Need</h2>
            <p className="hero-description mt-0 mt-md-3">
              Lorem ipsum dolor sit amet consectetur.
            </p>
            <div className="d-flex gap-4 my-4 mt-md-5 justify-content-center">
              <Button className="px-5 border border-primary" variant="outline-primary" onClick={() => initiateQuote("quote")}>
                Check Quote
                {(loading.state && loading.index === 'quote') && <Spinner animation="border" size="sm" className="ms-2" />}
              </Button>
            </div>
          </div>
          <div className="mx-4 mb-5">
            <EmployeeInfo imgAlt="vehicles" name="Vehicles to help you move"
              imgClassName="w-100 h-100 rounded-4" textOrder={true}
              imgSrc="https://storage.googleapis.com/public_storage_truq/website/moveByTruQ_delivery_worker.jpg" textClassName="text-medium font-size-16 w-600" headerClassName="text-primary ms-0 mt-2"
              description="Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetur lacus. Feugiat massa commodo tristique facilisi ullamcorper posuere arcu luctus sed. Urna felis lacus feugiat dolor mauris ante tortor. Tortor commodo dui erat lectus phasellus urna nullam non." />

            <EmployeeInfo imgAlt="loaders" name="Loaders to do the heavy lifting" imgClassName="w-100 h-100 ms-md-5 rounded-4 image-fluid" textOrder={true} imgOrder={ColumnOrder.FIRST}
              imgSrc="https://storage.googleapis.com/public_storage_truq/website/moveByTruQ_delivery_man_with_cart_and_boxesdksyddulkvnmdh.jpg" textClassName="text-medium font-size-16 w-600 w-100 ms-md-5" headerClassName="text-dark ms-0 ms-md-5 mt-2"
              description="Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetur Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetur" />
            <EmployeeInfo imgAlt="ancillary_services" name="Ancillary services partners for your move needs" imgClassName="w-100 h-100 rounded-4" textOrder={true}
              imgSrc="https://storage.googleapis.com/public_storage_truq/website/moveByTruQ_repairing_ventilation.jpg" textClassName="text-medium font-size-16 w-600" headerClassName="text-dark w-460 ms-0 mt-2"
              description="Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetur Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetur." />
            <EmployeeInfo imgAlt="packaging_materials" name="Packaging materials for a compact move" imgClassName="w-100 h-100 ms-md-5 rounded-4" textOrder={true} imgOrder={ColumnOrder.FIRST}
              imgSrc="https://storage.googleapis.com/public_storage_truq/website/movebytruQ_postal_workergfiayvsral.jpg" textClassName="text-medium font-size-16 w-600 ms-md-5" headerClassName="text-dark ms-0 ms-md-5 w-460 mt-2"
              description="Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetur Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetur" />
            <EmployeeInfo imgAlt="domestic_services" name="Domestic service partners for your home management" imgClassName="w-100 h-100 rounded-4" textOrder={true}
              imgSrc="https://storage.googleapis.com/public_storage_truq/website/moveByTruQ_cleaning_tools.jpg" textClassName="text-medium font-size-16 w-600" headerClassName="text-dark w-460 ms-0 mt-2"
              description="Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetur" />
          </div>
        </section>

        <section className="d-flex flex-column my-5 mt-md-130">
          <h2 className="text-darker w-720 w-100 align-self-center text-center">
            Discover a Fast, Smart and Easy Moving Experience With truQ
          </h2>
          <Row className="my-5">
            <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 0 }}>
              <CollapsibleText collapsibleTextData={MovingExperience} />
            </Col>
            <Col xs={{ span: 12, order: 0 }} lg={{ span: 6, order: 1 }} className="m-auto">
              <Image
                alt="lazy"
                src="https://storage.googleapis.com/public_storage_truq/website/moveByTruQ_couple_moving_into_new_house.jpg"
                height={489} width={642} className="m-auto rounded-4"
              />
            </Col>
          </Row>
        </section>

        <section className="webapp-banner pt-5 my-5 mt-md-130">
          <div className="d-flex flex-column align-items-center mb-4">
            <h4 className="text-black">
              Designed with you in mind!
            </h4>
            <div className="w-771 w-100">
              {/* TODO: MAP THIS CONTENT */}
              <div className="upgrade-options mb-5">
                {UpgradeOptions.map((item, index) => 
                  <div className="upgrade-option" key={index}>
                  <img loading="lazy" src={CheckSVG} alt="check" className="upgrade-icon" />
                  <span>{item.option}</span>
                  </div>
                )}
              <div className="d-flex gap-md-4 gap-3 my-3 justify-content-center">
                <Button className="px-md-5 px-4" variant="primary" onClick={() => initiateQuote("bookBanner")}>
                  Book Now
                  {(loading.state && loading.index === 'bookBanner') && <Spinner animation="border" size="sm" className="ms-2" />}
                </Button>
                <Button className="px-md-5 px-4 border border-primary" variant="outline-primary" onClick={() => initiateQuote("quoteBanner")}>
                  Check Quote
                  {(loading.state && loading.index === 'quoteBanner') && <Spinner animation="border" size="sm" className="ms-2" />}
                </Button>
              </div>
              </div>
            </div>

            <Image alt="HERO" src="https://storage.googleapis.com/public_storage_truq/website/movebytruq_banner_Imgertgtupqwreblrfdjamksdak.png" className="mx-auto" width={550} />
          </div>
        </section>
      </Container>

      <Testimony />
      <AppStoreBanner />
    </PageLayout>
  );
}

export default MoveByTruq;