import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Image from '../../fragment/Image';
import { LinkType } from '../../../modules/enums';

const NavLinkInfo = ({ imgAlt, imgSrc, title, subtitle, className }: Omit<IHeader, "link" | "link_type">) => (
  <div className='w-290 d-flex  my-3 cursor-pointer'>
    <div className='header-icon'><Image alt={imgAlt} src={imgSrc} /></div>
    <div className='ms-3 d-inline-flex flex-column'>
      <p className={`header-title ${className}`}>{title}</p>
      <p className="header-subtitle">{subtitle}</p>
    </div>
  </div>
)


const NavItem = ({ link, link_type, imgAlt, imgSrc, title, subtitle, className }: IHeader) => (
  <Fragment>
    {link_type === LinkType.IN_APP
      ? <NavLink to={link}> {NavLinkInfo({ imgAlt, imgSrc, title, subtitle, className })}</NavLink>
      : <a href={link} target="_blank" rel="noreferrer" > {NavLinkInfo({ imgAlt, imgSrc, title, subtitle, className })}</a>
    }
  </Fragment>
);

export default NavItem