export const generalAppInfo = {
    APP_NAME: "TruQ Website",
    ORG_NAME: "TruQ Dynamic Services",
    APP_VERSION: "v2",
    ORGANISATION: 'TRU_Z9WE',
	STAGING_ORGANISATION: 'TRU_H0D4',
    SIJU_PK: "pk_live_b7911570ac2162e04582a60a40322bbf",
    SIJU_STAGING_PK: "pk_sandbox_ebe242fafd51aad297ccfaebaa965fa1",
    API_BASE_URL: "https://dodge-apis-c2btvqbhlq-uc.a.run.app",
    API_STAGING_BASE_URL: "https://dodge-apis-staging-c2btvqbhlq-uc.a.run.app",
    BACKOFFICE_BASE_URL: "https://backoffice-production-2ric45myja-uc.a.run.app",
    BACKOFFICE_STAGING_BASE_URL: "https://backoffice-staging-2ric45myja-uc.a.run.app",
    CHECKOUT_STAGING_BASE_URL: "https://dodge-checkout-beta.vercel.app",
    CHECKOUT_BASE_URL: "https://checkout.siju.it",
  };


  export const Loading = {
    ERROR: 'ERROR',
    FAILED: 'FAILED',
    CREATED: 'CREATED',
    SUCCESS: 'SUCCESS',
    FETCHING: 'FETCHING',
    NO_CONTENT: 204,
  }