import Faqs from "../../assets/images/svgs/Faqs.svg";
import Demo from "../../assets/images/svgs/Demo.svg";
import Career from "../../assets/images/svgs/Career.svg";
// import TruqPay from "../../assets/images/svgs/truQ-pay.svg";
import AboutUs from "../../assets/images/svgs/about-us.svg";
import ApiDocs from "../../assets/images/svgs/api-docs.svg";
import DriverHub from "../../assets/images/svgs/drivehub.svg";
import SliderImage3 from "../../assets/images/pngs/slide3.jpg";
import ContactUs from "../../assets/images/svgs/contact-us.svg";
import UserGuide from "../../assets/images/svgs/user-guide.svg";
import SijuByTruq from "../../assets/images/svgs/siju-by-truQ.svg";
import MoveByTruq from "../../assets/images/svgs/move-by-truQ.svg";
import solution1 from "../../assets/images/pngs/truQPay/solution1.png";
import solution2 from "../../assets/images/pngs/truQPay/solution2.png";
import solution3 from "../../assets/images/pngs/truQPay/solution3.png";
import PartnershipBase from "../../assets/images/svgs/partnership.svg";
import DriveHubOrange from "../../assets/images/svgs/drivehub-orange.svg";
import SijuByTruqWhite from "../../assets/images/svgs/siju-by-truQ-white.svg";
import { LinkType, MenuType, NavItemType, SubMenuType, ColumnOrder } from "../../modules/enums";


// HEADER DATA
export const NavItems = [
  {
    name: "Products",
    type: NavItemType.PRODUCTS
  },
  {
    name: "Company",
    type: NavItemType.COMPANY
  },
  {
    name: "Resources",
    type: NavItemType.RESOURCES
  }
]

export const ProductsData: IHeader[] = [
  {
    imgAlt: "Driver_Hub",
    imgSrc: DriverHub,
    title: "Drivehub",
    subtitle: "Lorem ipsum dolor sit amet",
    link: `/${MenuType.PRODUCTS}/${SubMenuType.DRIVEHUB}`,
    link_type: LinkType.IN_APP
  },
  {
    imgAlt: "Move_By_Truq",
    imgSrc: MoveByTruq,
    title: "Move by truQ",
    subtitle: "Lorem ipsum dolor sit amet",
    link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ}`,
    link_type: LinkType.IN_APP
  },
  // {
  //   imgAlt: "Truq_Pay",
  //   imgSrc: TruqPay,
  //   title: "truQ Pay",
  //   subtitle: "Lorem ipsum dolor sit amet",
  //   link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_PAY}`,
  //   link_type: LinkType.IN_APP
  // },
  {
    imgAlt: "Siju by truQ",
    imgSrc: SijuByTruq,
    title: "Siju by truQ",
    subtitle: "Lorem ipsum dolor sit amet",
    link: `/${MenuType.PRODUCTS}/${SubMenuType.SIJU}`,
    link_type: LinkType.IN_APP
  }
]

export const CompanyData: IHeader[] = [
  {
    imgAlt: "About_Us",
    imgSrc: AboutUs,
    title: "About Us",
    subtitle: "Lorem ipsum dolor sit amet",
    link: `/${MenuType.COMPANY}/${SubMenuType.ABOUT_US}`,
    link_type: LinkType.IN_APP
  },
  {
    imgAlt: "Contact_Us",
    imgSrc: ContactUs,
    title: "Contact Us",
    subtitle: "Lorem ipsum dolor sit amet",
    link: `/${MenuType.COMPANY}/${SubMenuType.CONTACT_US}`,
    link_type: LinkType.IN_APP
  },
  {
    imgAlt: "Career",
    imgSrc: Career,
    title: "Career",
    subtitle: "Lorem ipsum dolor sit amet",
    link: `/${MenuType.COMPANY}/${SubMenuType.CAREER}`,
    link_type: LinkType.IN_APP
  },
]

export const ResourcesData: IHeader[] = [
  {
    imgAlt: "user_guide",
    imgSrc: UserGuide,
    title: "Users Guide",
    subtitle: "Lorem ipsum dolor sit amet",
    link: "https://docs.mytruq.com/",
    link_type: LinkType.TARGET
  },
  {
    imgAlt: "Api_Docs",
    imgSrc: ApiDocs,
    title: "API Docs",
    subtitle: "Lorem ipsum dolor sit amet",
    link: "https://truq-services.gitbook.io",
    link_type: LinkType.TARGET
  },
  {
    imgAlt: "Demo_Video",
    imgSrc: Demo,
    title: "Demo Video",
    subtitle: "Lorem ipsum dolor sit amet",
    link: `/${MenuType.CONSTRUCTION}`,
    link_type: LinkType.IN_APP
  },
  {
    imgAlt: "Partnership_Base",
    imgSrc: PartnershipBase,
    title: "Partnership Base",
    subtitle: "Lorem ipsum dolor sit amet",
    link: `/${MenuType.CONSTRUCTION}`,
    link_type: LinkType.IN_APP
  },
  {
    imgAlt: "Blogs",
    imgSrc: SijuByTruq,
    title: "Blogs",
    subtitle: "Lorem ipsum dolor sit amet",
    link: `/${MenuType.CONSTRUCTION}/`,
    link_type: LinkType.IN_APP
  },
  {
    imgAlt: "Faqs",
    imgSrc: Faqs,
    title: "FAQs",
    subtitle: "Lorem ipsum dolor sit amet",
    link: `/${MenuType.RESOURCES}/${SubMenuType.FREQUENTLY_ASKED_QUESTIONS}`,
    link_type: LinkType.IN_APP
  },
]

// FOOTER DATA
export const FooterLinks = [
  {
    title: "Product",
    className: "order-1",
    content: [
      {
        text: "Drivehub by truQ",
        link: `/${MenuType.PRODUCTS}/${SubMenuType.DRIVEHUB}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Move by truQ",
        link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Siju by truQ",
        link: `/${MenuType.PRODUCTS}/${SubMenuType.SIJU}`,
        link_type: LinkType.IN_APP
      },
      // {
      //   text: "truQ Pay",
      //   link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_PAY}`,
      //   link_type: LinkType.IN_APP
      // },
    ]
  },
  {
    title: "Company",
    className: "order-2 order-md-3",
    content: [
      {
        text: "About Us",
        link: `/${MenuType.COMPANY}/${SubMenuType.ABOUT_US}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Contact Us",
        link: `/${MenuType.COMPANY}/${SubMenuType.CONTACT_US}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Career",
        link: `/${MenuType.COMPANY}/${SubMenuType.CAREER}`,
        link_type: LinkType.IN_APP
      },
    ]
  },
  {
    title: "Legal",
    className: "order-5 order-md-4",
    content: [
      {
        text: "Terms of Service",
        link: `/${MenuType.LEGAL}/${SubMenuType.TERMS_CONDITION}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Privacy Policy",
        link: `/${MenuType.LEGAL}/${SubMenuType.PRIVACY_POLICY}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Terms for Drivers and Partners",
        link: `/${MenuType.LEGAL}/${SubMenuType.TERMS_DRIVER_PATNER}`,
        link_type: LinkType.IN_APP
      },
    ]
  },
  {
    title: "Resources",
    className: "order-3 order-md-2",
    content: [
      {
        text: "Users Guide",
        link: "https://truq-services.gitbook.io",
        link_type: LinkType.TARGET
      },
      {
        text: "API Docs",
        link: "https://docs.mytruq.com/",
        link_type: LinkType.TARGET
      },
      {
        text: "FAQs",
        link: `/${MenuType.RESOURCES}/${SubMenuType.FREQUENTLY_ASKED_QUESTIONS}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Blog",
        link: `/${MenuType.CONSTRUCTION}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Demo Videos",
        link: `/${MenuType.CONSTRUCTION}`,
        link_type: LinkType.IN_APP
      },
      {
        text: "Partnerships Base",
        link: `/${MenuType.CONSTRUCTION}`,
        link_type: LinkType.IN_APP
      },
    ]
  },
  {
    title: "Socials",
    className: "order-4 order-md-5",
    content: [
      {
        text: "LinkedIn",
        link: "https://www.linkedin.com/company/truq-logistics/",
        link_type: LinkType.TARGET
      },
      {
        text: "Facebook",
        link: "https://web.facebook.com/Mytruq/",
        link_type: LinkType.TARGET
      },
      {
        text: "Instagram",
        link: "https://www.instagram.com/mytruq/",
        link_type: LinkType.TARGET
      },
      {
        text: "Twitter",
        link: "https://twitter.com/MytruQ",
        link_type: LinkType.TARGET
      }
    ]
  },
];

// COMPONENTS DATA

export const CoreBeliefs = [
  {
    number: "i",
    belief: "Inefficient logistics processes would always be difficult and expensive to manage or scale for any business",
  },
  {
    number: "ii",
    belief: "Logistics success goes beyond just process optimization, it also heavily depends on the capability of the vehicle to be efficient",
  },
  {
    number: "iii",
    belief: "1 and 2 above are best executed in a connected system with minimal friction and fragmentation.",
  },
];

export const DeliveryGoals = [
  {
    border: "bg-primary",
    title: "Activate tech-enabled logistics operations from start to finish",
    description: "Moving now or later?, it's your call—your move, your schedule. Schedule your move at your discretion with guaranteed service."
  },
  {
    border: "bg-warning",
    title: "Access a range of vehicle sizes for your deliveriesless Connection",
    description: "Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetur"
  },
  {
    border: "bg-orange",
    title: "Save costs with aggregated trips and flexible pricing",
    description: "Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetur"
  },
  {
    border: "bg-primary",
    title: "Gain real-time oversight and visibility into your deliveries",
    description: "Lorem ipsum dolor sit amet consectetur. Risus sem semper quisque eu consectetu...."
  },
];

export const LogisticsUpgrade = [
  {
    border: "bg-primary",
    title: "Digitize all your hustle",
    description: "Log your trips, buy insurance per trip, receive payments, share real-time status update link, record your sales, track your expenses, and Issue invoices."
  },
  {
    border: "bg-warning",
    title: "Access Financial support for business",
    description: "Access financing for all your operational expenses, fueling, repairs, spare parts etc. without the hassle of paying at once."
  },
  {
    border: "bg-orange",
    title: "Get discounts on your expenses",
    description: "Enjoy discounts at fuel stations, mechanic workshops, spare part stores etc when you patronize any of our partner merchants."
  },
  {
    border: "bg-primary",
    title: "Earn more money",
    description: "Reduce your vehicle idle periods, get real time connections with people and businesses who need your service to earn more money."
  },
  {
    border: "bg-warning",
    title: "Connect with your people",
    description: "Connect with other operators, access training, tools, hangouts, and do business with community members."
  },
];

export const LogisticsUpgradeOptions = [
  {
    option: "Drivers"
  },
  {
    option: "Vehicle Owner"
  },
  {
    option: "Operators"
  },
];

export const MovingExperience = [
  {
    border: "bg-primary",
    title: "Move at your convenience",
    description: "Moving now or later?, it's your call—your move, your schedule. Schedule your move at your discretion with guaranteed service."
  },
  {
    border: "bg-warning",
    title: "Seamless Connection",
    description: "Get connected with the closest verified and trusted driver on truQ with real-time status updates on your trip."
  },
  {
    border: "bg-orange",
    title: "With Peace of mind and top-Notch Care",
    description: "Secure your belongings with boxes, shrink wraps, and bubble wraps for utmost safety with an added layer of comprehensive insurance on your items from pickup to drop off."
  },
  {
    border: "bg-primary",
    title: "No Heavy Lifting",
    description: "Request loaders to handle all your heavy lifting needs with ease. Leave the hard work to our loaders—they'll handle it all"
  },
  {
    border: "bg-warning",
    title: "Post Move Services",
    description: "Beyond just moving, leverage our wide range of domestic service partners and partnership discounts to access cleaners, carpenters, electricians, plumbers etc to save you time and help you enjoy your new space"
  },
];

export const Products = [
  {
    backgroundClassName: "driveHub-bg",
    textClassName: "text-dark",
    headerClassName: "text-orange",
    link: `/${MenuType.PRODUCTS}/${SubMenuType.DRIVEHUB}`,
    logoSrc: DriveHubOrange,
    logoClassName: "border-orange",
    description: "For ambitious drivers, helping you digitize your trips and collect payments, access financing, save on operational expenses, and earn more money.",
    title: "DriveHub by truQ"
  },
  {
    backgroundClassName: "moveByTruq-bg",
    textClassName: "text-dark",
    headerClassName: "text-primary",
    link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ}`,
    logoSrc: MoveByTruq,
    logoClassName: "border-blue",
    description: "Your complete moving partner, connecting you to the closest vehicle running on truQ and providing access to packaging materials and domestic artisans before, during and after your move.",
    title: "Move by truQ"
  },
  // {
  //   backgroundClassName: "truqPay-bg",
  //   textClassName: "text-dark",
  //   headerClassName: "text-primary",
  //  link: `/${MenuType.PRODUCTS}/${SubMenuType.TRUQ_PAY}`,
  //   logoSrc: TruqPay,
  //   logoClassName: "border-blue",
  //   description: "Designed to provide the most comprehensive financial support for Africa's peculiar logistics landscape and finance our customers across their logistics needs and operations.",
  //   title: "truQ Pay"
  // },
  {
    backgroundClassName: "siju-bg",
    textClassName: "text-white",
    headerClassName: "text-white",
    link: `/${MenuType.PRODUCTS}/${SubMenuType.SIJU}`,
    logoSrc: SijuByTruqWhite,
    logoClassName: "border-white",
    description: "For success-hungry businesses, helping you unlock the most efficient 3PL operations for delivery planning, tracking, reconciliation and fulfillment by drivers running on truQ, powering you into your revenue milestones.",
    title: "Siju by truQ"
  }
];

export const Slides = [
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/aboutUs_people_4.jpeg",
    alt: "Slide 1",
  },
  {
    src: SliderImage3,
    alt: "Slide 2",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/aboutUs_people_3.jpeg",
    alt: "Slide 3",
  },
  {
    src: SliderImage3,
    alt: "Slide 4",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/aboutUs_people_1.jpeg",
    alt: "Slide 1",
  },
  {
    src: SliderImage3,
    alt: "Slide 2",
  },
  {
    src: "https://storage.googleapis.com/public_storage_truq/website/career_truQstars_7.jpg",
    alt: "Slide 3",
  },
  {
    src: SliderImage3,
    alt: "Slide 4",
  },
]

export const Solutions = [
  {
    imgAlt: "truQDriverApp",
    imgSrc: solution1,
    name: "Drivehub by truQ",
    imgOrder: ColumnOrder.SECOND,
    spacing: "",
    details: [
      {
        subHeading: "i. Financing:",
        description: "Get your operational expenses sorted without the pressure of one off payment, everything from repairs, to spare parts, and insurance"
      },
      {
        subHeading: "ii. Microloans:",
        description: "Access quick loans for emergency needs and expenses"
      },
      {
        subHeading: "iii. Partial payment collection:",
        description: "Automate how you receive payments in full-pre trip, or in parts at pickup and drop off"
      },
      {
        subHeading: "iv. Fuel Card:",
        description: "Fuel fuel access fuel station with your special truQ card to enjoy fuel financing and discounts."
      },
      {
        subHeading: "v. Discounts:",
        description: "Get your operational needs serviced by our integrated partners to enjoy discounts and cashback on every transaction"
      },
      {
        subHeading: "vi. Early wage access:",
        description: "Get access to your earnings earlier than the payment cycle of any business you service"
      }
    ]
  },
  {
    imgAlt: "moveBytruQ",
    imgSrc: solution2,
    name: "Move by truQ",
    imgOrder: ColumnOrder.FIRST,
    spacing: "ms-md-5",
    details: [
      {
        subHeading: "i. Partial payment:",
        description: "Pay for your trip on your terms, in full-pre trip, or in parts at pickup and drop off"
      },
      {
        subHeading: "ii. Seamless Payment:",
        description: "Pay for your trip, additional moving services and domestic artisans without hassle"
      },
      {
        subHeading: "iii. truQ Points:",
        description: "Spend your truQ oints to access domestic artisans or on your next move"
      }
    ]
  },
  {
    imgAlt: "siju",
    imgSrc: solution3,
    name: "Siju by truQ",
    imgOrder: ColumnOrder.SECOND,
    spacing: "",
    details: [
      {
        subHeading: "i. Trip financing:",
        description: "Get your business deliveries done without the immediate cashflow and expense burden"
      },
      {
        subHeading: "ii. Wallet service:",
        description: "Pay for your trip, additional moving services and domestic artisans without hassle"
      },
      {
        subHeading: "iii. Wallet service:",
        description: "Leverage our wallet service to pay for your deliveries without the complication of bank transfers"
      }
    ]
  },
];

export const Stats = [
  {
    title: "3000+",
    description: "Drivers Running on truQ"
  },
  {
    title: "100k",
    description: "Trips Logged by Drivers"
  },
  {
    title: "$3M",
    description: "in Transaction Volume"
  },
  {
    title: "$1M",
    description: "In Financing and Discounts"
  },
];

export const Steps = [
  {
    number: "i",
    title: "Signup",
    description: "Provide simple personal details",
  },
  {
    number: "ii",
    title: "Upload Required Documents",
    description: "Get Approved. Take less than 24 hours to verify all your documentation",
  },
  {
    number: "iii",
    title: "Welcome to the level of your logistics operation",
    description: "Lorem ipsum dolor sit amet consectetur. Pellentesque quis nulla tempor a sit odio.",
  },
];

export const Testimonies = [
  {
    content: "Had a very good experience, the move was smooth and the people went above and beyond to make sure everything was intact.",
    image: null,
    author: "Abdussamad Yisau",
    medium: "X",
    imageBg: "bg-info"
  },
  {
    content: "Good guys 😊, with nice customer care and proper attention to details",
    image: null,
    author: "Faseyiku Alexander",
    medium: "",
    imageBg: "bg-success"
  },
  {
    content: "Very seamless experience. Exceptional driver as well. ",
    image: null,
    author: "SoftPal CSSL",
    medium: "",
    imageBg: "bg-secondary"
  },
  {
    content: "Their services are awesome. From asking about what your needs are to seeing through to making sure that you are satisfied. The driver I got attached to was so nice and highly professional. I did not regret using their service.",
    image: null,
    author: "Morenike R. Daniel",
    medium: "",
    imageBg: "bg-info"
  },
  {
    content: "They are highly professional with exceptional service. I was so lucky a friend referred them when I was moving. I wouldn't choose any other company, from consultation to delivery; all great. It's always amazing to see companies that are dedicated, with top-notch service in Nigeria. I would definitely recommend it! ",
    image: null,
    author: "M S",
    medium: "",
    imageBg: "bg-success"
  },
  {
    content: "I enjoyed the customer service of truQ. They were very helpful and patient. It felt like I was talking to a friend. The driver was also very polite. Kudos to truQ!",
    image: null,
    author: "Omo Imade",
    medium: "",
    imageBg: "bg-secondary"
  },
  {
    content: `I'll use truQ service over and over again if I have to. I was worried at first, but they handled the whole process with ease. 
Customer relations from the drivers to the customer care services are top-notch`,
    image: null,
    author: "Mide Isaac",
    medium: "",
    imageBg: "bg-info"
  },
  {
    content: "This was my first time using a moving service and Truq came through pretty amazing and smooth service. Customer service was superb. Great job and service @Morenike really amazing 👏 👌 👍",
    image: null,
    author: "Nwabuaku Okafor",
    medium: "",
    imageBg: "bg-success"
  },
]

export const UpgradeOptions = [
  {
    option: "Book your move"
  },
  {
    option: "Connect instantly with the nearest driver"
  },
  {
    option: "Driver arrives promptly as scheduled"
  },
  {
    option: "Driver gets paid upon delivery completion"
  },
  {
    option: "Receive real time updates on your move"
  },
];