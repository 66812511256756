import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

import Image from "../../components/fragment/Image";
import Brands from "../../components/fragment/Brands";
import { useTheme } from "../../components/hooks/useTheme";
import Shake from "../../assets/images/pngs/handshake.png";
import VisionSvg from "../../assets/images/svgs/vsion.svg";
import PageLayout from "../../components/layouts/PageLayout";
import MissionSvg from "../../assets/images/svgs/mission.svg";
import { AppTheme, MenuType, SubMenuType } from "../../modules/enums";
import AppStoreBanner from "../../components/fragment/AppStoreBanner";
import { CoreBeliefs } from "../../utils/mapper/Mapper";

const AboutUs = () => {
  const navigate = useNavigate(), appTheme = useTheme();

  useEffect(() => {
    if (appTheme.theme === AppTheme.LIGHT) appTheme.toggleTheme(AppTheme.DARK);
  }, []);

  return (
    <PageLayout headerTheme={AppTheme.DARK} footerTheme={AppTheme.DARK}>
      <Container className="my-5 mt-md-70">
        <section className="grid-white mt-5 px-md-4 px-2">
          <h2 className="text-center align-self-center pt-4 pt-md-0">
            We are a one-stop shop for commercial fleet operators and businesses, providing solutions that make their third-party logistics
            interactions smarter, faster and more efficient
          </h2>
          <Image
            alt="HERO"
            src="https://storage.googleapis.com/public_storage_truq/website/aboutUs_delivery_vans.jpg"
            className="mx-auto my-5 rounded-4"
          />
        </section>
        <section className="mx-3 px-md-5 mt-5 mt-md-130">
          <h2 className="text-center align-self-center mb-md-5 mt-0">truQ is built on 3 <span className="text-orange">Core Beliefs</span></h2>
          <Row className="my-md-5 justify-content-between">
            {/* TODO: MAP THIS CONTENT */}
        
            <Col lg={{span: 6 }} style={{maxHeight: "400px", maxWidth: "395px"}} className="mx-auto">
              <Image
                alt="beliefs"
                src="https://storage.googleapis.com/public_storage_truq/website/aboutUs_williams_receiving_award.jpeg"
                className="my-4 rounded-4 image-fluid"             
               />
            </Col>
            <Col lg={{ span: 6 }} className="my-lg-auto mt-5 ps-0 ps-lg-3">
              {CoreBeliefs.map((item, index) =>
                 <div className="d-flex mb-4" key={index}>
                    <p className="icon-avatar bg-light-orange w-20 h-20 m-3">{item.number}</p>
                    <p className="font-size-16 my-auto">
                      {item.belief}
                    </p>
                  </div>
              )}
            </Col>
          </Row>
        </section>

        <section className="px-4 py-5 mt-5 mt-md-130">
            <div className="d-flex flex-column flex-md-row justify-content-center row-gap-4 column-gap-3 ">
                <Card className="bg-lighter-orange w-100" style={{maxWidth: "450px", height: "350px"}}>
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <Image src={MissionSvg} alt="mission" className="icon-avatar bg-primary-blue" />
                    <div>
                      <h5 className="card-title ms-0">Our Mission</h5>
                      <p className="card-text">To empower commercial fleet operators with everything they need to operate a thriving business with fulfilled operators</p>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="bg-light-blue w-100" style={{maxWidth: "450px", height: "350px"}}>
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <Image src={VisionSvg} alt="vision" className="icon-avatar bg-lighter-yellow" />
                    <div>
                      <h5 className="card-title ms-0">Our Vision</h5>
                      <p className="card-text">We envision a pan-African future where every logistics-related economic and commercial activity is faster, smarter, and better than ever because of the work we do at truQ</p>
                    </div>
                  </Card.Body>
                </Card>
            </div>
        </section>

        <section className="text-center  mt-5 mt-md-130">
          <h2 className="mb-2">
            Our People <Image src={Shake} alt="handshake" width={50} height={50} className="ms-2" />
          </h2>
          <p className="text-center w-720 mx-auto mb-4 px-2">truQstars are the secret sauce behind the magic we cook at truQ, different people, expertise and background but are aligned in vision for what’s possible for Africa from our kitchen at truQ</p>
          <Button className="mx-auto mb-5" onClick={() => navigate(`/${MenuType.COMPANY}/${SubMenuType.CAREER}`)}>
            Discover Open Position
          </Button>
            <Row className="py-2 w-100 mx-auto">
              <Col md={6} className="mb-3">
              <div className="people-1"/>
              </Col>
              <Col md={6} className="mb-3">
              <div className="people-2"/>
              </Col>
              <Col md={4} className="mb-3">
                <div className="people-3"/>
              </Col>
              <Col md={3} className="mb-3">
                <div className="people-4"/>
              </Col>
              <Col md={5} className="mb-3">
              <div className="people-5"/>
              </Col>
            </Row>
        </section>
      </Container>

      <Brands />

      <AppStoreBanner />
    </PageLayout>
  );
}

export default AboutUs;