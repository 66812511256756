import { dodgeInstance as Axios, backOfficeInstance as Backoffice} from "./axios.service";
import { AxiosResponse } from "axios";

const APIService = {
  initiateTrip(): Promise<AxiosResponse> {
    return Axios({
      method: 'POST',
      url: `booking-service/booking/booking-trip/check_quote/`,
    });
  },

  sendMessage(payload: IMessageRequest): Promise<AxiosResponse> {
    return Backoffice({
      method: 'POST',
      url: `/messaging-service/messaging/contact-us`,
      data: payload
    });
  },
  
}

export default APIService;