import { useEffect } from "react";
import { AppTheme, MenuType } from "../../modules/enums";
import { LogisticsUpgrade, LogisticsUpgradeOptions, Products } from "../../utils/mapper/Mapper";
import { Button, Col, Container, Row } from "react-bootstrap";
import Image from "../../components/fragment/Image";
import CheckSVG from "../../assets/images/svgs/check.svg";
import { useTheme } from "../../components/hooks/useTheme";
import PageLayout from "../../components/layouts/PageLayout";
import Testimony from "../../components/fragment/Testimony";
import AppStoreBanner from "../../components/fragment/AppStoreBanner";
import CompanyStat from "../../components/fragment/CompanyStat";
import CollapsibleText from "../../components/fragment/CollapsibleText";
import { NavLink, useNavigate } from "react-router-dom";


const Home = () => {
  const appTheme = useTheme(), navigate = useNavigate();
  useEffect(() => {
    if (appTheme.theme === AppTheme.LIGHT) appTheme.toggleTheme(AppTheme.DARK);
  }, [appTheme.theme]);

  return (
    <PageLayout headerTheme={AppTheme.DARK} footerTheme={AppTheme.DARK}>
      <section>
        <div className="hero-content home-hero">
          <h1 className="mt-md-70">
            Supercharge Your Logistics <span className="text-orange">Hustle!</span>
          </h1>
          <p className="hero-description">
            Digitize your trips, access financing for your operations, enjoy discounts on your everyday expenses, and connect with people that need your vehicle.
          </p>
          <div className="d-flex gap-4 mb-5 mt-3">
            <Button className="px-4 px-md-5" variant="primary" onClick={() => navigate(`/${MenuType.CONSTRUCTION}`)}>Get Started</Button>
            <Button className="px-4 px-md-5" variant="dark" onClick={() => navigate(`/${MenuType.CONSTRUCTION}`)}>Learn More</Button>
          </div>

          <div className="w-100 w-500 align-self-center">
            <Image alt="HERO" src="https://storage.googleapis.com/public_storage_truq/website/home_two_phones.png" className="my-3 image-responsive" />
          </div>
        </div>
      </section>

      <CompanyStat />

      <main className="light-theme pb-5 d-flex justify-content-center flex-column">

        <Container>
          <section className="upgrade-section my-5 mt-md-130 mx-auto">
            <h2 className="text-center">It's time to upgrade your logistics hustle</h2>
            <div className="upgrade-options">
              {/* TODO: MAP THIS CONTENT */}
              {LogisticsUpgradeOptions.map((item, index) =>
                <div className="upgrade-option" key={index}>
                  <img loading="lazy" src={CheckSVG} alt="check" className="upgrade-icon" />
                  <span>{item.option}</span>
                </div>
              )}
            </div>
          </section>
          <section className="ms-2 mx-lg-150 my-5">
            <Row>
              <Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 0 }}>
                <CollapsibleText collapsibleTextData={LogisticsUpgrade} />
              </Col>
              <Col xs={{ span: 12, order: 0 }} lg={{ span: 6, order: 1 }} className="m-auto">
                <Image
                  alt="training_session"
                  src="https://storage.googleapis.com/public_storage_truq/website/home_partner_training.jpeg"
                  className="my-auto rounded-4"
                />
              </Col>
            </Row>
          </section>

          <section className="my-5 mt-md-70 pb-5">
            <h2 className="text-center mx-md-auto">Our Products</h2>
            <Container>
              <Row>
                {Products.map((product, index) => (
                  
                  <Col sm={`${index === 0 ? 12 : 6}`} className="mt-3 mt-lg-5" key={index}>
                    <NavLink to={product.link}>
                      <div className={`${product.backgroundClassName} p-3`}>
                        <div className="d-flex flex-column justify-content-between h-100 my-2">
                          <div className={`productLogo-container ${product.logoClassName}`}>
                            <img loading="lazy" src={product.logoSrc} alt={`${product.title} logo`} width={25} height={25}/>
                          </div>
                          <div>
                            <p className={`${product.textClassName} font-size-14 ${index === 0 ? "w-500" : ""}`}>{product.description}</p>
                            <h4 className={`py-2 ${product.headerClassName}`}>{product.title}</h4>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </Col>
                ))}
              </Row>
            </Container>
          </section>
        </Container>
        <Testimony />
        <AppStoreBanner />
      </main>
    </PageLayout>
  );
}

export default Home;