import Image from "./Image";
import { ColumnOrder } from "../../modules/enums";
import { Col, Row } from "react-bootstrap";

type Props = {
  imgAlt: string;
  imgSrc: string;
  imgClassName?: string;
  textOrder?: boolean;
  textClassName?: string;
  headerClassName?: string;
  name: string;
  description: string;
  imgOrder?: ColumnOrder,
};

const EmployeeInfo = ({ imgAlt, imgSrc, imgClassName, name, description, imgOrder, textOrder, textClassName, headerClassName }: Props) => (
  <Row className="mb-4 py-3 mt-md-70">
    <Col  md={{ span: 4, order: imgOrder === ColumnOrder.FIRST ? 0 : 1 }} className="h-300">
      <Image alt={imgAlt} src={imgSrc} className={imgClassName} />
    </Col>

    <Col md={7} className={`d-flex flex-column ms-0 ms-md-5 my-auto ${textOrder ? "row-gap-2 row-gap-md-3 " :"row-gap-3 row-gap-md-5 "}`}>
        <p className={`${textClassName}`}>{description}</p>
        <h4 className={`${textOrder ? "order-first" : ""} ${headerClassName}`}>{name}</h4>
    </Col>
  </Row>
);


export default EmployeeInfo