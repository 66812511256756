import { Fragment, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Image } from "react-bootstrap";

import NavItem from "./NavItem";
import MobileHeader from "./MobileHeader";
import { useTheme } from "../../hooks/useTheme";
import { useMobileNav } from "../../hooks/useMobileNav";
import MenuSVG from "../../../assets/images/svgs/menu.svg";
import truQLogo from "../../../assets/images/pngs/truq-logo.png";
import ArrowUpSVG from "../../../assets/images/svgs/arrow-up.svg";
import MenuWhiteSVG from "../../../assets/images/svgs/menu-white.svg";
import ArrowDownSVG from "../../../assets/images/svgs/arrow-down.svg";
import { AppTheme, MenuType, NavItemType } from "../../../modules/enums";
import ArrowUpWhiteSVG from "../../../assets/images/svgs/arrow-up-white.svg";
import truQLogoWhite from "../../../assets/images/pngs/truq-logo-white.png";
import ArrowDownWhiteSVG from "../../../assets/images/svgs/arrow-down-white.svg";
import { CompanyData, NavItems, ProductsData, ResourcesData } from "../../../utils/mapper/Mapper";


const Header = ({ theme = AppTheme.LIGHT }: { theme?: AppTheme }) => {
  const appTheme = useTheme(), navMode = useMobileNav(), navigate = useNavigate();
  const [activeNavItem, setActiveNavItem] = useState<NavItemType | null>(null);
  const darkMode = appTheme.theme === AppTheme.DARK || theme === AppTheme.DARK;
  const itemData = !activeNavItem ? null : activeNavItem === NavItemType.PRODUCTS ? ProductsData : activeNavItem === NavItemType.COMPANY ? CompanyData : ResourcesData;

  return (
    <Fragment>
      <header className={`fixed-top ${darkMode ? "bg-dark" : "bg-white"}`}>
        <nav>
          <NavLink to={`/${MenuType.HOME}`} className="my-auto">
            {darkMode ?
              <Image src={truQLogoWhite} alt="truQ Logo" width={60} height={25} />
              : <Image src={truQLogo} alt="truQ Logo" width={60} height={25} />
            }
          </NavLink>
          <ul className="nav col-12 col-lg-auto mx-lg-auto  justify-content-center font-size-14 gap-2 gap-md-5 mb-md-0">
            {NavItems.map(item =>
              <li key={item.type} onMouseEnter={() => setActiveNavItem(item.type)}>
                <button className={`nav-link px-2 ${darkMode ? "text-white" : ""}`}>{item.name}
                  {activeNavItem === item.type ? <Image
                    className="ms-2"
                    alt="ARROW_DOWN"
                    src={darkMode ? ArrowUpWhiteSVG : ArrowUpSVG} />
                    : <Image
                      className="ms-2"
                      alt="ARROW_DOWN"
                      src={darkMode ? ArrowDownWhiteSVG : ArrowDownSVG} />
                  }
                </button>
              </li>
            )}
          </ul>

          <div className="header-actions gap-4">
            <Button type="button" variant="link" onClick={() => navigate(`/${MenuType.CONSTRUCTION}`)}
              className={`btn ps-0 ${darkMode ? "text-white" : "text-primary"}`}>
              Login
            </Button>
            <Button type="button" className="btn btn-primary" onClick={() => navigate(`/${MenuType.CONSTRUCTION}`)}>Get Started</Button>
          </div>

          {!navMode.navMenu &&
            <Image className="menu" src={darkMode ? MenuWhiteSVG : MenuSVG} alt="menu" width={24} height={24} onClick={() => navMode.toggleNavMenu(true)} />}
        </nav>

        {itemData &&
          <div className={`header-item-container mx-auto ${activeNavItem ? "show" : "hide"}`} onMouseLeave={() => setActiveNavItem(null)}>
            {itemData.map((data, index) =>
              <NavItem key={index} {...data} className={darkMode ? "text-white" : ""}
              />
            )}
          </div>
        }
      </header>

      <MobileHeader isDarkMode={darkMode} />
    </Fragment>
  );
}

export default Header;