import { PolicyLinkPlacement, LinkType } from "../../modules/enums";

export const DriverPartnerTerms: IPolicies[] = [
  {
    title: `Commencement and Duration`,
    description: `This agreement is effective from the date you accept these terms and remains valid for as long as you are a Driver-Partner on this platform unless sooner terminated by either party in accordance with the terms and conditions of this Agreement.
                Provided that the Driver-Partner will be notified of any changes we make to these Terms.`
  },
  {
    title: `Services`,
    description: `The service(s) for which the drivers have been engaged (hereinafter called the “Services”) is for Drivers to work with the Company by registering their vehicles with the Company on its platform for the purpose of moving and delivery services in furtherance of the Company’s business objectives.`
  },
  {
    title: `Age`,
    description: `These terms are offered to Driver-Partners who are 21 years of age or older. By using the Company's platform, you represent and warrant that you are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the platform.`
  },
  {
    title: `Working Hours`,
    description: [
      `Driver-Partners have the discretion to determine their working hours. You may therefore accept or decline requests made by businesses or individuals that use truQ services ("truQ customers") at your discretion.`,
      `Working hours may further be determined taking into cognizance the security situations of certain locations and delivery routes.`
    ]
  },
  {
    title: `Vehicle Documents and Insurance`,
    description: `All Driver-Partners are required to submit certain documentation and information when signing up on the platform. These documents include but are not limited to;`,
    extras: [
      {
        description: `Valid driver's license`
      },
      {
        description: `Current house address`
      },
      {
        description: `Certificate of road worthiness`
      },
      {
        description: `Guarantor's information and means of identification`
      },
      {
        description: `Hackney's permit`
      },
      {
        description: `valid Basic vehicle insurance`
      },
      {
        description: `Criminal record (if applicable)`
      },
      {
        description: `All licenses, permits, insurance, registrations, approvals, certifications and other documentation required in the applicable jurisdiction for providing moving and delivery services.`
      }
    ]
  },
  {
    title: `Obligations of the Company`,
    extras: [
      {
        subTitle: `The Company`,
        description: `The Company shall provide the platform where Partner-Drivers can do the following through an app that does the following:`,
        extra: [
          `Connects them to people and businesses that want to move heavy or large items.`,
          `Provide an algorithm that calculates the fare price of all vehicle categories in real time.`,
          `Provide mapping and real-time updates to navigate the road on trips.`,
          `A wallet that lets Partner-Drivers withdraw their funds upon trip competition.`,
          `Give data information on request insights.`,
          `Provide information on effective moving services.`
        ]
      }
    ]
  },
  {
    title: `Payment Calculation`,
    description: `Driver-Partners shall be paid in the following ways depending on the type of service offered on the platform; (include a note here for more specifics as to instances that would determine where driver would be paid bi-monthly or 24/48 hours`,

    extras: [
      {
        description: `Bi-monthly; or`,
      },
      {
        description: `24-48 hours after delivery of goods to the destination`
      }
    ],
    footer: `Payment shall be calculated based on the size of the vehicle, the nature of goods being moved and distance between the pickup and drop off.`
  },
  {
    title: `Obligations of the Driver-Partners`,
    extras: [
      {
        description: `The Driver-Partner shall ensure to keep the information and documentation which they submit on this platform updated.`
      },
      {
        description: `The Driver-Partner shall own a GPS-supporting mobile device.`
      },
      {
        description: `The Driver-Partner shall own a speed limit device.`
      },
      {
        description: `The Driver-Partner shall at all times possess and maintain the validity of all licences, permits, insurance, registrations, approvals, certifications and other documentation required in the applicable jurisdiction for providing transportation services.`
      },
      {
        description: `The Driver-Partner shall pack or arrange goods in the vehicle in ways that prevent damages to the Company’s customer’s items.`
      },
      {
        description: `The Driver-Partner shall ensure at all times that the vehicle is in proper condition, safe to be ridden in and is fit for purpose at all times.`
      },
      {
        description: `Where delivery of the goods will be delayed for any reason, Driver-Partner shall immediately communicate such information to us including the reason(s) for such delay.`
      },
      {
        description: `The Driver-Partner shall not conduct themselves in a manner that brings bad reputation to the Company or disservice to the customers and users of the Company’s platform.`
      },
      {
        description: `The Driver-Partner shall not engage or provide services outside the Company’s platform to truQ customers - businesses and individuals.`
      },
      {
        description: `The Driver-Partner shall not engage in any physical or aggravated verbal exchange with the customers and users of the Company’s platform.`
      },
      {
        description: `The Driver-Partner shall load and offload items in good condition at the pickup and drop off locations.`
      },
      {
        description: `The Driver-Partner shall not be abusive or disrespectful to the customers and users of the Company’s platform.`
      },
      {
        description: `The Driver-Partner shall not under any circumstances request for extra charges from the customers and users of the Company’s platform.`
      },
      {
        description: ` The Driver-Partner shall bear all costs necessary to the performance of the services on this platform including but not limited to vehicle maintenance, fuel, insurance, relevant taxes, amortization of the vehicle, and duty fees.`
      },
      {
        description: `The Driver-Partner shall not steal or damage customer’s goods during transit and prior to delivery to customers and users of the Company’s platform.`
      }
    ]
  },
  {
    title: `No Partnership/Employment`,
    extras: [
      {
        description: `The Driver-Partner enters into this Agreement as, and shall continue to be, an Independent Driver-Partner. All Services shall be performed only by the Driver-Partner.`
      },
      {
        description: `Neither the Driver-Partner, nor any of the Driver-Partner's assigned driver, shall be entitled to any benefits accorded to Company's employees, including and without limitation to worker's compensation, disability insurance, vacation, or sick pay except for the agreed payment for services being rendered under this Agreement.`
      },
      {
        description: `The Driver-Partner shall be responsible for providing, at the Driver-Partner's expense, and in the Driver-Partner's name, unemployment, disability, worker's compensation and other insurance, as well as licenses and permits, usual or necessary for conducting the Services.`
      },
    ]
  },
  {
    title: `Confidential Information`,
    extras: [
      {
        description: `Confidential Information includes all information which has or could have commercial value or other utility in the business in which the Company is engaged or contemplates engaging in, or information which the unauthorised disclosure of, could be detrimental to the interests of the Company, whether or not such information is identified by the Company.`
      },
      {
        description: `The Company now owns and will hereafter develop, compile, and own certain proprietary techniques, trade secrets, and confidential information which have great value in its business. These include without limitation any and all information concerning discoveries, developments, designs, improvements, inventions, formulas, software programs, processes, techniques, know-how, data, research techniques, customer and supplier lists, marketing, sales or other financial or business information, scripts, and all derivatives, improvements and enhancements to any of the above. The Company Information also includes third-party information which is in the Company's possession under an obligation of confidential treatment. (collectively, “Confidential Information”).`
      },
      {
        description: `The Driver-Partner agrees that the Company will during its engagement with the Driver-Partner disclose Confidential Information to the Driver-Partner. Confidential Information includes not only information disclosed by the Company, but also information developed or learned by the Driver-Partner during the Driver-Partner's performance of the Services.`
      },
      {
        description: `The Driver-Partner always agrees that during or subsequent to the performance of the Service(s), the Driver-Partner will keep confidential and not divulge, communicate, or use Confidential Information, except for the Driver-Partner's own use during the term of this Agreement to the extent necessary to perform the Services. The Driver-Partner further agrees not to cause the transmission, removal, or transport of tangible embodiments of, or electronic files containing, Confidential Information from the Company's principal place of business or any of its assets, without the prior written approval of the Company.`
      },
      {
        description: `The Driver-Partner's obligations with respect to any portion of the Confidential Information as set forth above shall not apply when the Driver-Partner can show that;`,
        extra: [
          `It was in the public domain at the time it was communicated to the Driver-Partner by the Company.`,
          `It entered the public domain subsequent to the time it was communicated to the Driver-Partner by the Company through no fault of the Driver-Partner.`,
          `It was in Driver-Partner's possession free of any obligation of confidence at the time it was communicated to Driver-Partner by the Company, or`,
          `It was rightfully communicated to the Driver-Partner free of any obligation of confidence.`
        ]
      },
    ]
  },
  {
    title: `Complaint Procedure`,
    extras: [
      {
        subTitle: ` Driver-Partners are to submit all complaints to this email address`,
        description: `Driver-Partners are to include the following information in the email;`,
        link: {
          href: `contact@mytruq.com`,
          type: LinkType.EMAIL,
          placement: PolicyLinkPlacement.SUBTITLE
        },
        extra: [
          `Name of the complainant (Driver-Partner) and contact details`,
          `Nature of the complaint`,
          `Relevant accompanying evidence (if applicable)`,
          `Details of the specific truQ customer (where the complaint is about a truQ customer)`,
          `Any other relevant information`
        ]
      },
      {
        description: `Upon receipt of the complaint, we shall respond in the following ways;`,
        extra: [
          `Within 24 hours where the complaint is non-complex`,
          `Within 5 to 10 working days, where the complaint requires further investigation or verification`,
          `Where the complaint is complex and requires a longer period to be resolved, we shall notify Driver-Partner accordingly, stating the reasons where necessary. The duration of the extension shall be determined on a case-by-case basis.`,
          `We shall request for further information where we deem it necessary.`
        ]
      },
      {
        description: `Where the Driver-Partner is dissatisfied with our final decision, Driver-Partner may respond to our email requesting for a review of our decision and stating reasons for the dissatisfaction. Upon receipt of the email, we shall review our decision and respond to Driver-Partner as to whether we shall uphold or modify our decision.`
      },
    ]
  },
  {
    title: `Limitation of Liability`,
    extras: [
      { description: `This Platform is provided on an "as is" and "as available" basis. We do not represent, warrant or guarantee that access to the platform will be uninterrupted or error free.` },
      { description: `We shall not be liable for any loss or damage that may incur to Driver-Partner or Driver-Partner's vehicle as a result of provision of the services on this platform. Driver-Partner shall have the right to claim for damages only if we have deliberately violated these Terms or Agreement.` },
      { description: `Driver-Partner shall be fully liable for breach of these Terms, Agreement or any other applicable laws or regulations and must remedy such breach immediately after receipt of a respective demand from us or any state authority.` },
      { description: `Where a truQ customer presents any claims against us in connection with Driver-Partner's provision of the services via this platform including but not limited to damage to goods or fragile items, Driver-Partner shall compensate for such damage to us in full within 10 (ten) days of confirmation and receipt of the respective request from us.` },
      { description: `Driver-Partner shall compensate us with any legal costs related to evaluation of the damages and submission of claims relating to compensation for such damage.` },
    ]
  },
  {
    title: `Representations and Warranties`,
    description: `The Driver-Partner represents and warrants to the Company that;`,
    extras: [
      { description: `The Driver-Partner has full power and authority to enter into this Agreement including all rights necessary to make the foregoing assignments to the Company; performing under the Agreement the Driver-Partner will not violate the terms of any agreement with any third party; and` },
      { description: `The Driver-Partner shall indemnify and hold the Company and its successors, assigns and licensees harmless from any and all claims, actions and proceedings, and the resulting losses, damages, costs and expenses (including reasonable attorneys' fees) arising from any claim, action or proceeding based upon or in any way related to the Driver-Partner's breach or alleged breach of any representation, warranty or covenant in this Agreement, and/or from the acts or omissions of the Driver-Partner.` },
    ]
  },
  {
    title: `Termination and Effect of termination`,
    description: [
      `This Agreement is terminable by either party at any time, without cause, effective upon a written notice not less than forty-eight (48) hours to the other party.`,
      `The Company can terminate this Agreement immediately on the occurrence of the following circumstances:`
    ],
    extras: [
      { description: `The Driver-Partner becomes incapable to perform the service(s) contemplated in this Agreement; or` },
      { description: `The Driver-Partner breaches any term of this Agreement and fails to remedy such breach within two (2) weeks after a notice of the same has been given by the Company` },
      { description: `The Driver-Partner is indicted for fraud.` },
      { description: `The Driver-Partner is adjudged insolvent and/or bankrupt by a court of competent jurisdiction.` },
      { description: `The Driver-Partner dies and there is no representative to continue with the services envisaged herein.` },
    ],
    footer: [
      `If the Company exercises its right to terminate the Agreement, any obligation it may otherwise have under this Agreement shall cease immediately, except that the Company shall be obligated to compensate the Driver-Partner on quantum meruit basis for work performed up to the time of termination.`,
      `If the Driver-Partner exercises its right to terminate the Agreement, any obligation it may otherwise have under this Agreement shall cease immediately, provided that services for which the Company had paid the Driver-Partner in advance shall be completed immediately, or a refund is made by the Driver-Partner to the Company for the uncompleted portion of the service.`,
      `In the event of the occurrence the event envisaged in clause 8.2. above, the Company shall be obligated to pay Driver-Partner’s estate or beneficiaries only the accrued but unpaid compensation and expenses due as of the date of death.`
    ]
  },
  {
    title: `Governing law and dispute resolution`,
    description: [
      `This Agreement, and any
                    dispute or claim arising out of or in connection with it or
                    its subject matter or formation (including non-contractual
                    disputes or claims), shall be governed by and construed in
                    accordance with the laws of the Federal Republic of Nigeria;   Any dispute, difference
                    or claim arising out of or in connection with this Agreement
                    shall be settled amicably and in good faith between the
                    Parties within 14 (fourteen) days of notice of such dispute,
                    difference or claim from one Party to the other, failing
                    which such dispute, difference or claim shall be referred to
                    and finally determined by mediation in accordance with the
                    Rules and Practice Directions made pursuant to the Lagos
                    State Multi-Door Courthouse Law No. 21 of 2007 or any
                    amendment thereto.`,
      ` The Mediation shall
                    consist of one (1) Mediator to be agreed between the
                    Parties. The Mediation shall take place in Lagos and shall
                    be conducted in the English language provided that the
                    inclusion of this mediation clause shall not prevent a Party
                    from seeking urgent relief from a competent court in Nigeria
                    in appropriate circumstances. Each Party shall bear their
                    own costs in relation to the mediation.  The decision of the
                    Mediation shall be final and binding on the Parties and
                    shall be reduced into writing in an agreement executed by
                    the Parties, which shall thereafter be presented to a Court
                    of competent jurisdiction for adoption as an enforceable
                    judgement of the said Court in respect of the dispute,
                    difference or claim.`
    ]
  },
  {
    title: `Binding Effect`,
    description: `This Agreement is binding upon, and inure to the benefit of, the successors, executors, heirs, representatives, administrators, and permitted assigns of the parties hereto.`
  },
  {
    title: `No Assignment`,
    description: `The Driver-Partner shall have no right to`,
    extras: [
      { description: `Assign this Agreement, by operation of law or otherwise; or` },
      { description: `Subcontract or otherwise delegate the performance of the Services without Company’s prior written consent which may be withheld as Company determines in its sole discretion. Any such purported assignment shall be void.` },
    ]
  },
  {
    title: `Indemnity`,
    description: `The Driver-Partner hereby indemnifies and holds the Company harmless from any claims, losses, costs, fees, liabilities, damages, or injuries suffered by Company arising out of Driver-Partner's failure with respect to Driver-Partner’s obligations under this Agreement.`
  },
  {
    title: `Severability`,
    description: `If any provision of this Agreement shall be found invalid or unenforceable, the remainder of this Agreement shall be interpreted so as best to reasonably effect the intent of the parties.`
  },
  {
    title: `Entire Agreement`,
    description: `This Agreement constitutes the entire understanding and agreement of the parties with respect to its subject matter and supersedes all prior and contemporaneous agreements or understandings, inducements, or conditions, express or implied, written, or oral, between the parties.`
  },
  {
    title: `Amendment and Waivers`,
    description: `Any term or provision of this Agreement may be amended, and the observance of any term of this Agreement may be waived, only by a writing signed by the party to be bound. The waiver by a party of any breach or default in performance shall not be deemed to constitute a waiver of any other or succeeding breach or default. The failure of any party to enforce any of the provisions hereof shall not be construed to be a waiver of the right of such party thereafter to enforce such provisions.`
  },
  {
    title: `Notice`,
    description: `Any notice, demand, or request with respect to this Agreement shall be in writing and shall be effective only if it is delivered by personal service, or by courier with receipt of delivery, or mailed, certified mail, return receipt requested, postage prepaid, to the address set forth above. Such communications shall be effective when they are received by the addressee; but if sent by certified mail in the manner set forth above, they shall be effective five (5) days after being deposited in the mail. Any party may change its address for such communications by giving notice to the other party in conformity with this section.`
  },
]