import Image from "./Image";
import AppStore from "../buttons/AppStore";
import PlayStore from "../buttons/PlayStore";
import { Container } from "react-bootstrap";

const AppStoreBanner = () => (
  <Container className="app-store-banner">
    <div className="inner-container mx-auto">
      <div className="d-flex justify-content-between gap-lg-5 flex-column flex-lg-row">
        <div className="my-auto" style={{ maxWidth: "500px" }}>
          <div className="text-white">
            <h2 className="text-center text-lg-start">
              Empower Your Drive,
              Drive & Earn Today!
            </h2>
            <p className="font-size-18 text-center text-lg-start mt-2 mb-5">Download the truQ driver app to manage all driver activities</p>
          </div>
          <div className="d-flex justify-content-center justify-content-lg-start gap-md-3 gap-2 my-3">
            <PlayStore />
            <AppStore />
          </div>
        </div>
        <div className="d-flex align-items-end" style={{maxWidth: "500px"}}>
          <Image
            alt="PHONE"
            src="https://storage.googleapis.com/public_storage_truq/website/banner_phone.png"
            className="image-fluid mt-5 mt-lg-0"/>
        </div>
      </div>
    </div>
  </Container>
);

export default AppStoreBanner;