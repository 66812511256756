import { NavLink, useNavigate } from "react-router-dom";
import { Button, Offcanvas } from "react-bootstrap";

import NavItem from "./NavItem";
import Image from "../../fragment/Image";
import { useMobileNav } from "../../hooks/useMobileNav";
import { MenuType, NavItemType } from "../../../modules/enums";
import closeSVG from "../../../assets/images/svgs/close.svg";
import truQLogo from "../../../assets/images/pngs/truq-logo.png";
import closeWhiteSVG from "../../../assets/images/svgs/close-white.svg";
import truQLogoWhite from "../../../assets/images/pngs/truq-logo-white.png";
import { CompanyData, NavItems, ProductsData, ResourcesData } from "../../../utils/mapper/Mapper";


const MobileHeader = ({ isDarkMode }: { isDarkMode: boolean }) => {
  const navMode = useMobileNav(), navigate = useNavigate();
  const handleClose = () => navMode.toggleNavMenu(false);

  return (
    <div className="d-lg-none">
      <Offcanvas show={navMode.navMenu} onHide={handleClose} placement="start" backdrop="static" responsive="lg"
        className={`mobile-header ${isDarkMode ? "bg-dark" : "bg-white"}`}>
        <Offcanvas.Header className="d-flex justify-content-between" closeVariant={isDarkMode ? "white" : "dark"}>
          <Offcanvas.Title>
            <NavLink to={`/${MenuType.HOME}`} className="my-auto">
              {isDarkMode ?
                <Image src={truQLogoWhite} alt="truQ Logo" width={60} height={25} />
                : <Image src={truQLogo} alt="truQ Logo" width={60} height={25} />
              }
            </NavLink>
          </Offcanvas.Title>
          <Button type="button" variant="link" className="px-0" onClick={handleClose}>
            {isDarkMode ?
              <Image src={closeWhiteSVG} alt="close" width={24} height={24} />
              : <Image src={closeSVG} alt="close" width={24} height={24} />
            }
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul>
            {NavItems.map(item =>
              <li key={item.type}>
                <h5 className={`${isDarkMode && "text-white"}`}>{item.name}</h5>

                {(item.type === NavItemType.PRODUCTS) ? ProductsData.map((data, index) =>
                  <NavItem key={index} {...data} className={isDarkMode ? "text-white" : ""}
                  />)
                  : (item.type === NavItemType.COMPANY) ? CompanyData.map((data, index) =>
                    <NavItem key={index} {...data} className={isDarkMode ? "text-white" : ""}
                    />)
                    : ResourcesData.map((data, index) =>
                      <NavItem key={index} {...data} className={isDarkMode ? "text-white" : ""}
                      />)
                }
              </li>
            )}
          </ul>
          <div className="button-group">
            <Button type="button" variant="link" onClick={() => navigate(`/${MenuType.CONSTRUCTION}`)}
              className={`fs-6 ${isDarkMode ? "text-white" : "text-primary"}`}>
              Login
            </Button>
            <Button type="button" variant="primary" className="px-4" onClick={() => navigate(`/${MenuType.CONSTRUCTION}`)}>Get Started</Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default MobileHeader;